import React, { Component } from 'react'
import compose from 'recompose/compose'
import clsx from 'clsx'
import complexity from 'complexity'
import { FormattedMessage } from 'react-intl'
import is from 'is_js'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Tooltip from 'Components/common/Tooltip'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import * as Icons from 'Icons'

const styles = () => ({
  barColor: {
    backgroundColor: red[100],
  },
  formControl: {
    width: '100%',
  },
  hand: {
    cursor: 'pointer',
  },
  progress: {
    height: 2,
    // marginTop: -10,
    width: '100%',
  },
  root: {
    height: 10,
  },
  passwordInvalid: {
    backgroundColor: red[500],
  },
  passwordValid: {
    backgroundColor: green[500],
  },
  tooltip: {
    fontSize: 15,
  },
  tooltipList: {
    paddingLeft: 0,
  },
})

export const passwordOptions = {
  uppercase: 1,
  digit: 1,
  lowercase: 1,
  min: 8,
}

export class TextField extends Component {
  state = {
    anchorEl: null,
    menuOpen: false,
    showPassword: false,
    value: 0,
  }

  handleChange = (event) => {
    if (this.props.passwordStrength && this.props.meta.active) {
      const value = event.target.value
      const check = complexity.checkError(value, passwordOptions)
      this.setState(() => ({
        value:
          (check.min ? 25 : 0) +
          (check.digit ? 25 : 0) +
          (check.uppercase ? 25 : 0) +
          (check.lowercase ? 25 : 0),
      }))
    }
    if (this.props.upper && event.target.value) {
      event.target.value = event.target.value.toUpperCase()
    }
    if (this.props.lower && event.target.value) {
      event.target.value = event.target.value.toLowerCase()
    }
    if (event.target && event.target.value && this.props.type === 'email') {
      event.target.value = (event.target.value || '').replace(
        /[^a-zA-Z0-9_\-@.+]/g,
        ''
      )
    }
    this.props.input.onChange && this.props.input.onChange(event)
    this.props.onChange && this.props.onChange(event)
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  handleClickShowPasssword = () => {
    this.setState(() => ({ showPassword: !this.state.showPassword }))
  }

  handleClose = () => {
    this.setState(() => ({
      menuOpen: false,
    }))
  }

  handleMenuClick = (event) => {
    this.setState({
      menuOpen: true,
      anchorEl: event.currentTarget,
    })
  }

  handleMenuItemClick = (value) => () => {
    this.handleChange(value)
    this.handleClose()
  }
  renderEndAdornment = () => {
    const {
      classes,
      disabled,
      items,
      password,
      passwordPreview = true,
    } = this.props
    if (password && passwordPreview) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={this.handleClickShowPasssword}
            onMouseDown={this.handleMouseDownPassword}
            tabIndex="-1"
            disabled={disabled}
          >
            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      )
    }
    if (items && items.length > 0) {
      return (
        <>
          <InputAdornment position="end">
            <ArrowDropDownIcon
              className={classes.hand}
              onClick={this.handleMenuClick}
            />
          </InputAdornment>
          <Menu
            open={this.state.menuOpen}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
          >
            {items.map((item) => {
              const Icon = item.icon && Icons[item.icon]
              return (
                <div key={item.description}>
                  {item.description === '-' ? (
                    <Divider />
                  ) : (
                    <MenuItem
                      onClick={this.handleMenuItemClick(item.description)}
                    >
                      {Icon && (
                        <Icon style={{ fill: item.color, marginRight: 10 }} />
                      )}
                      {item.description}
                    </MenuItem>
                  )}
                </div>
              )
            })}
          </Menu>
        </>
      )
    }
    return null
  }

  render = () => {
    const {
      classes,
      input,
      fullWidth = true,
      label,
      meta: { touched, error, active },
      password,
      passwordStrength,
      required,
      inputLabelProps,
      multiline,
      ignoreLp = true,
      upper,
      lower,
      items,
      rows = 3,
      ...other
    } = this.props
    let inputProps = {}
    if (ignoreLp) {
      inputProps['data-lpignore'] = true
    }
    other.autoFocus = is.mobile() || is.tablet() ? false : other.autoFocus
    return (
      <FormControl
        className={clsx(fullWidth && classes.formControl)}
        error={touched && !!error}
        required={required}
      >
        <InputLabel {...inputLabelProps} htmlFor={input.name}>
          {label}
        </InputLabel>
        <Input
          {...input}
          rows={rows}
          multiline={multiline}
          inputProps={inputProps}
          autoComplete="new-password"
          autoCorrect="off"
          autoCapitalize="off"
          {...other}
          type={
            password
              ? this.state.showPassword
                ? 'text'
                : 'password'
              : other.type || 'text'
          }
          endAdornment={this.renderEndAdornment()}
          onChange={this.handleChange}
          margin="dense"
        />
        <FormHelperText>
          {!(passwordStrength && active) && touched && error}
        </FormHelperText>
        {passwordStrength && active && (
          <Tooltip
            open={this.state.value > 0 && this.state.value < 100}
            title={
              <div className={classes.tooltip}>
                <FormattedMessage id="common.form.textField.passwordStrength.title" />
                <ul className={classes.tooltipList}>
                  <li>
                    <FormattedMessage id="common.form.textField.passwordStrength.capital" />
                  </li>
                  <li>
                    <FormattedMessage id="common.form.textField.passwordStrength.digit" />
                  </li>
                  <li>
                    <FormattedMessage id="common.form.textField.passwordStrength.lowercase" />
                  </li>
                </ul>
              </div>
            }
            placement="bottom-start"
          >
            <LinearProgress
              variant="determinate"
              value={this.state.value}
              classes={{
                root: classes.root,
                barColorPrimary:
                  this.state.value < 100
                    ? classes.passwordInvalid
                    : classes.passwordValid,
                colorPrimary: classes.barColor,
              }}
              className={classes.progress}
            />
          </Tooltip>
        )}
      </FormControl>
    )
  }
}

export default compose(withStyles(styles))(TextField)

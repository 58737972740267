import * as types from 'Actions/types'
import { createAction } from 'Utils/redux'

export const addCompanies = createAction(types.CACHE_ADD_COMPANIES, 'data')
export const addFilterDefaults = createAction(
  types.CACHE_ADD_FILTER_DEFAULTS,
  'data'
)
export const addFilters = createAction(types.CACHE_ADD_FILTERS, 'data')
export const addLookups = createAction(types.CACHE_ADD_LOOKUPS, 'data')
export const addTags = createAction(types.CACHE_ADD_TAGS, 'entityType', 'data')
export const addTag = createAction(types.CACHE_ADD_TAG, 'name', 'entityType')
export const addTemplates = createAction(types.CACHE_ADD_TEMPLATES, 'data')
export const addUsers = createAction(types.CACHE_ADD_USERS, 'data')
export const addViewers = createAction(types.CACHE_ADD_VIEWERS, 'data')
export const clearTags = createAction(types.CACHE_CLEAR_TAGS, 'entityType')
export const filtersLoaded = createAction(types.CACHE_FILTERS_LOADED)
export const loadCompanies = createAction(types.CACHE_LOAD_COMPANIES)
export const loadCompaniesFinished = createAction(
  types.CACHE_LOAD_COMPANIES_FINISHED
)
export const loadFilters = createAction(types.CACHE_LOAD_FILTERS, 'force')
export const loadLookups = createAction(types.CACHE_LOAD_LOOKUPS)
export const loadTags = createAction(types.CACHE_LOAD_TAGS, 'fromEntity')
export const loadTemplates = createAction(types.CACHE_LOAD_TEMPLATES, 'user')
export const loadUsers = createAction(types.CACHE_LOAD_USERS)
export const loadUsersFinished = createAction(types.CACHE_LOAD_USERS_FINISHED)
export const loadViewers = createAction(types.CACHE_LOAD_VIEWERS)
export const loadingCompanies = createAction(
  types.CACHE_LOADING_COMPANIES,
  'value'
)
export const loadingTemplates = createAction(
  types.CACHE_LOADING_TEMPLATES,
  'value'
)
export const loadingUsers = createAction(types.CACHE_LOADING_USERS, 'value')
export const removeTag = createAction(
  types.CACHE_REMOVE_TAG,
  'name',
  'entityType'
)
export const setUserFilter = createAction(
  types.CACHE_ENTITIES_SET_USER_FILTER,
  'filter'
)
export const setUserFilterType = createAction(
  types.CACHE_ENTITIES_SET_USER_FILTER_TYPE,
  'filter'
)
export const setCompanyFilter = createAction(
  types.CACHE_ENTITIES_SET_COMPANY_FILTER,
  'filter'
)

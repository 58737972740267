import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const First2Find = (props) => (
  <SvgIcon viewBox="213.273 5.201 35.063 35.063" {...props}>
    <path
      fill="#EDC413"
      d="m234.7 11.096-6.698-4.198.397-1.697 6.445 3.818-.144 2.077ZM234.138 18.316l-7.635-5 .469-2.02 7.339 4.707-.173 2.313ZM232.069 26.282l-7.289-5.73.504-2.007 8.4 5.73-.13 1.67s0 .473-.159.716c-.158.244-.375.366-.375.366l-.951-.738v-.007Z"
    />
    <path d="M227.065 40.264c2.095 0 3.792-1.687 3.792-3.768 0-2.08-1.697-3.768-3.792-3.768-2.094 0-3.792 1.687-3.792 3.768 0 2.081 1.698 3.768 3.792 3.768Z" />
  </SvgIcon>
)

export default React.memo(First2Find)

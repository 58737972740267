import * as types from './types'
import { createAction } from 'Utils/redux'

export const addCandidate = createAction(
  types.JOB_ADD_CANDIDATE,
  'phase',
  'candidate',
  'index'
)
export const addLanguage = createAction(types.JOB_ADD_LANGUAGE, 'payload')
export const addSources = createAction(types.JOB_ADD_SOURCES, 'data')
export const askReview = createAction(types.JOB_ASK_REVIEW, 'payload')
export const askSendEmail = createAction(types.JOB_ASK_SEND_EMAIL, 'payload')
export const assignPlacementTo = createAction(
  types.JOB_ASSIGN_PLACEMENT_TO,
  'payload'
)
export const changeBranch = createAction(types.JOB_CHANGE_BRANCH, 'branch')
export const checkPhases = createAction(types.JOB_CHECK_PHASES)
export const clearPlacements = createAction(types.JOB_CLEAR_PLACEMENTS)
export const copy = createAction(types.JOB_COPY, 'payload')
export const deleteAlternative = createAction(
  types.JOB_DELETE_ALTERNATIVE,
  'id'
)
export const deleteAlternativeSucceed = createAction(
  types.JOB_DELETE_ALTERNATIVE_SUCCEED
)
export const deleteLanguage = createAction(
  types.JOB_DELETE_LANGUAGE,
  'id',
  'language',
  'description'
)
export const deleteVacancy = createAction(types.JOB_DELETE_VACANCY, 'id')
export const deleteVacancySucceed = createAction(
  types.JOB_DELETE_VACANCY_SUCCEED
)
export const disableSelectMode = createAction(types.JOB_DISABLE_SELECT_MODE)
export const editLanguage = createAction(types.JOB_EDIT_LANGUAGE, 'value')
export const getCandidateViewers = createAction(
  types.JOB_GET_CANDIDATE_VIEWERS,
  'id',
  'candidateId'
)
export const getLanguage = createAction(
  types.JOB_GET_LANGUAGE,
  'id',
  'language'
)
export const getLanguageSucceed = createAction(
  types.JOB_GET_LANGUAGE_SUCCEED,
  'data'
)
export const getPlacements = createAction(
  types.JOB_GET_PLACEMENTS,
  'id',
  'selectCandidates'
)
export const getReviewUsers = createAction(types.JOB_GET_REVIEW_USERS, 'id')
export const getReviewUsersSucceed = createAction(
  types.JOB_GET_REVIEW_USERS_SUCCEED,
  'data'
)
export const getViewers = createAction(
  types.JOB_GET_VIEWERS,
  'id',
  'customerId'
)
export const getViewersSucceed = createAction(
  types.JOB_GET_VIEWERS_SUCCEED,
  'data'
)
export const loadCandidates = createAction(
  types.JOB_LOAD_CANDIDATES,
  'container',
  'id'
)
export const loadSources = createAction(types.JOB_LOAD_SOURCES)
export const moveCandidate = createAction(
  types.JOB_MOVE_CANDIDATE,
  'fromPhase',
  'toPhase',
  'candidateId',
  'reason'
)
export const moveCandidates = createAction(
  types.JOB_MOVE_CANDIDATES,
  'toPhase',
  'reason'
)
export const netiveConfirmCancel = createAction(types.JOB_NETIVE_CONFIRM_CANCEL)
export const netiveConfirmYes = createAction(types.JOB_NETIVE_CONFIRM_YES)

export const netiveGet = createAction(types.JOB_NETIVE_GET, 'id')
export const netiveGetJson = createAction(types.JOB_NETIVE_GET_JSON, 'id')
export const netiveSet = createAction(types.JOB_NETIVE_SET, 'data')
export const netiveSetJson = createAction(types.JOB_NETIVE_SET_JSON, 'json')
export const netiveSetProposal = createAction(
  types.JOB_NETIVE_SET_PROPOSAL,
  'proposal'
)
export const netiveUpdateProposal = createAction(
  types.JOB_NETIVE_UPDATE_PROPOSAL,
  'id',
  'candidateId',
  'data'
)
export const removeCandidate = createAction(
  types.JOB_REMOVE_CANDIDATE_INIT,
  'candidateId',
  'name'
)
export const removeCandidateSucceed = createAction(
  types.JOB_REMOVE_CANDIDATE,
  'candidateId'
)
export const removeImage = createAction(types.JOB_REMOVE_IMAGE, 'payload')
export const selectCandidates = createAction(
  types.JOB_SELECT_CANDIDATES,
  'id',
  'candidates',
  'reload'
)
export const selectPhase = createAction(types.JOB_SELECT_PHASE, 'payload')
export const selectPhaseCandidate = createAction(
  types.JOB_SELECT_PHASE_CANDIDATE,
  'candidates'
)
export const setBarButton = createAction(
  types.JOB_SET_BARBUTTON,
  'button',
  'visible',
  'disabled'
)
export const setBoardFilter = createAction(
  types.JOB_SET_BOARDFILTER,
  'filter',
  'id'
)
export const setBoardFilterOrigin = createAction(
  types.JOB_SET_BOARDFILTER_ORIGIN,
  'filter',
  'id'
)
export const setBoardFilterSelect = createAction(
  types.JOB_SET_BOARDFILTER_SELECT,
  'filter',
  'id'
)
export const setPlaceholderIndex = createAction(
  types.JOB_SET_PLACEHOLDERINDEX,
  'placeholderIndex'
)
export const setPlacementOnhold = createAction(
  types.JOB_SET_PLACEMENT_ONHOLD,
  'payload'
)
export const setPlacementOrigin = createAction(
  types.JOB_SET_PLACEMENT_ORIGIN,
  'payload'
)
export const setPlacementStatus = createAction(
  types.JOB_SET_PLACEMENT_STATUS,
  'payload'
)
export const setSelectBarCandidateId = createAction(
  types.JOB_SELECTBAR_SET_CANDIDATEID,
  'value'
)
export const setSelectBarMatching = createAction(
  types.JOB_SELECTBAR_SET_MATCHING,
  'value'
)
export const setScrollPosition = createAction(
  types.JOB_SET_SCROLL_POSITION,
  'id',
  'top',
  'left'
)
export const setSourcesDirty = createAction(
  types.JOB_SET_SOURCES_DIRTY,
  'value'
)
export const setUploading = createAction(
  types.JOB_SET_UPLOADING,
  'doc',
  'value'
)
export const timelineLoad = createAction(
  types.JOB_TIMELINE_LOAD,
  'jobId',
  'candidateId'
)
export const timelineLoadSucceed = createAction(
  types.JOB_TIMELINE_LOAD_SUCCEED,
  'values'
)
export const updateImage = createAction(types.JOB_UPDATE_IMAGE, 'payload')
export const updateLanguage = createAction(
  types.JOB_UPDATE_LANGUAGE,
  'id',
  'language',
  'values'
)
export const updatePublish = createAction(
  types.JOB_UPDATE_PUBLISH,
  'id',
  'values'
)

export const uploadAlternative = createAction(
  types.JOB_UPLOAD_ALTERNATIVE,
  'id',
  'file'
)
export const uploadDescription = createAction(
  types.JOB_UPLOAD_DESCRIPTION,
  'payload'
)
export const uploadVacancy = createAction(
  types.JOB_UPLOAD_VACANCY,
  'id',
  'file'
)
export const zoomInPdf = createAction(types.JOB_PDF_ZOOMIN)
export const zoomOutPdf = createAction(types.JOB_PDF_ZOOMOUT)

import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const taskReducer = createReducer(initialState.task, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.task,
  [types.TASK_ADD_MY_TASKS]: (state, action) => ({
    ...state,
    my: action.data,
  }),
  [types.TASK_ADD_TASKS]: (state, action) => ({
    ...state,
    list: action.data,
  }),
  [types.TASK_COMPLETED]: (state, action) => {
    const { id, completed } = action
    const index = state.list.findIndex((task) => task.id === id)
    if (index !== -1) {
      return {
        ...state,
        list: [
          ...state.list.slice(0, index),
          { ...state.list[index], completed },
          ...state.list.slice(index + 1),
        ],
      }
    } else {
      return state
    }
  },
  [types.TASK_GET_ITEM_SUCCEED]: (state, { data }) => ({
    ...state,
    item: data,
  }),
  [types.TASK_GET_SHARED_TASKS_SUCCEED]: (state, { users }) => {
    return {
      ...state,
      sharedBy: users,
    }
  },
  [types.TASK_SET_FILTER]: (state, { filter }) => ({
    ...state,
    filter,
  }),
  [types.TASK_SET_FILTER_LOCATION]: (state, { filter }) => ({
    ...state,
    filterLocation: filter,
  }),
  [types.TASK_SET_FORM_OPEN]: (state, { value }) => ({
    ...state,
    formOpen: value,
  }),
  [types.TASK_SET_LOADING]: (state, { value }) => ({
    ...state,
    loading: value,
  }),
  [types.TASK_SET_SEARCH_FILTER]: (state, { filter }) => ({
    ...state,
    searchFilter: filter,
  }),
  [types.TASK_SET_SHARED_USERS]: (state, { users }) => ({
    ...state,
    users,
  }),
  [types.TASK_SET_TAB_VALUE]: (state, { tab }) => ({
    ...state,
    tab,
  }),
})

export default taskReducer

import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Carv = (props) => (
  <SvgIcon viewBox="0 0 33 33" {...props}>
    <path
      fill="#F49507"
      d="M24.767 14.972a7.236 7.236 0 1 0 0-14.472 7.236 7.236 0 0 0 0 14.472ZM24.767 32.5a7.236 7.236 0 1 0 0-14.472 7.236 7.236 0 0 0 0 14.472ZM15.988.525C7.158.525 0 7.683 0 16.512 0 25.343 7.158 32.5 15.988 32.5V.525Z"
    />
  </SvgIcon>
)

export default React.memo(Carv)

import { call, delay, put, race, select, take } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { hide } from 'redux-modal'
import { confirmSaga, globalError } from './shell'
import { show } from 'redux-modal'
import { gotoUrl } from 'Actions/app'
import { showMessage } from 'Actions/shell'
import * as types from 'Actions/types'
import {
  addSources,
  askSendEmail,
  checkPhases,
  deleteAlternativeSucceed,
  deleteVacancySucceed,
  disableSelectMode,
  editLanguage,
  getLanguageSucceed,
  getPlacements,
  getReviewUsersSucceed,
  getViewersSucceed,
  loadCandidates,
  netiveSet,
  netiveSetJson,
  netiveSetProposal,
  removeCandidateSucceed,
  selectPhaseCandidate,
  setUploading,
  timelineLoadSucceed,
} from 'Actions/job'
import { getByLine } from 'Actions/address'
import { updateField } from 'Actions/form'
import {
  get,
  refresh,
  setEditMode,
  setLoading,
  setNoteViewers,
  showConfetti,
} from 'Actions/entity'
import { t } from 'Root/app/IntlProvider'
import { messageLevel } from 'Utils/constants'
import { getTemplateName } from 'Selectors/template'
import { showBulkEmail, showNewEmail } from 'Actions/email'

export function* addLanguageSaga(action) {
  const {
    payload: { reject, resolve, values },
  } = action
  const { id, language, translate } = values
  const { created, error, data } = yield http.post(`/jobs/${id}/languages`, {
    language,
    translate,
  })
  if (created) {
    yield put(get(id))
    yield call(resolve)
    yield put(getLanguageSucceed(data))
    yield put(gotoUrl({ url: `/jobs/${id}/languages/${language}` }))
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* askReviewSaga(action) {
  const { jobId, ...rest } = action.payload
  yield put(hide('reviewRequest'))
  const { ok, error, data } = yield http.post(`/jobs/${jobId}/review`, rest)
  if (ok) {
    yield put(
      showMessage(t('common.reviewRequest.message.send'), messageLevel.info)
    )
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* askSendEmailSaga(action) {
  const { payload } = action
  const { candidates, jobs, template, phase, scheduledAt } = payload
  const list = candidates
    .filter((candidate) => candidate.email)
    .map((candidate) => `<br>${candidate.name} (${candidate.email})`)
    .join('')
  if (list.length === 0) {
    return
  }
  const state = yield select()
  const templateName = getTemplateName(state, template)

  const values = {
    title: t('job.message.placement.email.title'),
    content: t('job.message.placement.email.content', {
      candidates: list,
      template: templateName,
    }),
  }
  if (jobs.length === 1) {
    values.options = {
      cancelVisible: true,
      cancelCaption: t('job.message.placement.email.manual'),
    }
  }
  const result = yield call(confirmSaga, values)
  switch (result) {
    case true:
      for (const job of jobs) {
        yield http.post(`/jobs/${job.id}/placements/send-phase-email`, {
          candidateId: candidates.map((candidate) => candidate.id),
          toPhase: phase,
          scheduledAt,
        })
      }
      yield put(
        showMessage(
          t(`job.message.${scheduledAt ? 'scheduleEmail' : 'sendEmail'}`),
          messageLevel.success,
          3000
        )
      )
      break
    case false:
      break
    default:
      if (candidates.length > 1) {
        yield put(
          showBulkEmail({
            recipients: candidates.map((candidate) => ({
              id: candidate.id,
              name: candidate.name,
              email: candidate.email,
              entity: 'candidate',
            })),
            context: ['jobCandidate', 'job', 'candidate'],
            entities: {
              job: jobs[0].id,
            },
            template,
            templateName,
            label: phase,
            scheduledAt,
          })
        )
      } else if (jobs.length === 1) {
        const candidate = candidates[0]
        yield put(
          showNewEmail({
            to: [candidate.id],
            toEmail: candidate.email,
            context: ['jobCandidate', 'job', 'candidate'],
            entities: {
              candidate: candidate.id,
              job: jobs[0].id,
            },
            candidateId: candidate.id,
            jobName: jobs[0].name,
            jobId: jobs[0].id,
            label: phase,
            templateId: template,
            scheduledAt,
          })
        )
      }
      break
  }
}

export function* assignPlacementToSaga(action) {
  const {
    payload: { candidateId, jobId, assignedToUserId },
  } = action
  const { ok, data } = yield http.put(`/jobs/${jobId}/placements/assign`, {
    candidateId,
    assignedToUserId,
  })
  if (ok) {
    // do nothing
  } else {
    yield call(globalError, data)
  }
}

export function* changeBranchSaga(action) {
  const { branch } = action
  const state = yield select()
  const branches = state.cache.lookups.branch
  if (branches && branches[branch] && branches[branch].info) {
    const yes = yield call(confirmSaga, {
      title: t('job.message.changeBranch.title'),
      content: t('job.message.changeBranch.content'),
    })
    if (!yes) {
      return
    }
    const address = branches[branch].info
    yield put(getByLine(address, 'job', ''))
    // const address = branches[branch].info
    // const { ok, error, data } = yield http.get(
    //   `/addresses/format?address=${encodeURIComponent(address)}`
    // )
    // if (ok) {
    //   console.log(data)
    //   // update job
    // } else if (error) {
    //   yield call(globalError, data)
    // }
  }
}

export function* copySaga(action) {
  const {
    payload: { id, name, reject, resolve },
  } = action
  const { created, error, data } = yield http.post(`/jobs/copy/${id}`, { name })
  if (created) {
    yield call(resolve)
    yield put(gotoUrl({ url: `/jobs/${data.id}`, prevLocationEntity: '' }))
    yield put(setEditMode(true))
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* deleteAlternativeSaga(action) {
  const { id } = action
  const ok = yield call(confirmSaga, {
    title: t('job.message.deleteAlternative.title'),
    content: t('job.message.deleteAlternative.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(
    `/jobs/${id}/alternative`
  )
  if (noContent) {
    yield put(deleteAlternativeSucceed())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteLanguageSaga(action) {
  const { id, description, language } = action
  const ok = yield call(confirmSaga, {
    title: t('job.message.removeLanguage.title'),
    content: t('job.message.removeLanguage.content', { language: description }),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(
    `/jobs/${id}/languages/${language}`
  )
  if (noContent) {
    // ok
    yield put(get(id))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteVacancySaga(action) {
  const { id } = action
  const ok = yield call(confirmSaga, {
    title: t('job.message.deleteVacancy.title'),
    content: t('job.message.deleteVacancy.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/jobs/${id}/vacancy`)
  if (noContent) {
    yield put(deleteVacancySucceed())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getCandidateViewersSaga(action) {
  const { candidateId, id } = action
  const { ok, error, data } = yield http.get(
    `/jobs/${id}/viewers/${candidateId}`
  )
  if (ok) {
    yield put(setNoteViewers(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getLanguageSaga(action) {
  const { id, language } = action
  const { ok, error, data } = yield http.get(
    `/jobs/${id}/languages/${language}`
  )
  if (ok) {
    yield put(getLanguageSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getNetiveSaga(action) {
  const { id } = action
  const { ok, error, data, notFound } = yield http.get(`/jobs/${id}/netive`)
  if (ok) {
    yield put(netiveSet(data))
  } else if (notFound) {
    yield put(netiveSet({ error: true }))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getNetiveJsonSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/jobs/${id}/netive/json`)
  if (ok) {
    yield put(netiveSetJson(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getPlacementsSaga(action) {
  // debouce via takeLatest
  let { id, selectCandidates } = action

  const state = yield select()
  const entity = state.entity.info.entityType
  if (entity !== 'job') {
    return
  }
  if (!id) {
    id = state.entity.info.id
  }
  const oldId = state.job.board.id
  const selectMode = state.job.board.selectMode
  let { ok, error, data, notFound } = yield http.get(`/jobs/${id}/placements/`)
  if (ok) {
    if (id === oldId) {
      // set selection
      const phases = state.job.board.phases
      let selected = false
      data.phases = data.phases.map((x) => {
        const item = phases.find((y) => y.id === x.id)
        if (item) {
          x.selected = item.selected
          x.candidates = x.candidates.map((candidate) => {
            const found = item.candidates.find((z) => z.id === candidate.id)
            if (found) {
              candidate.selected = found.selected
              selected = true
            }
            return candidate
          })
        }
        return x
      })
      if (selected && !selectMode) {
        //
      } else if (!selected && selectMode) {
        yield put(disableSelectMode())
      }
    } else {
      // disabled selectmode
      if (selectMode) {
        yield put(disableSelectMode())
      }
    }
    yield put(loadCandidates(data, id))
    // ok
    if (selectCandidates) {
      yield put(selectPhaseCandidate(selectCandidates))
    }
  } else if (notFound) {
    // do nothing
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getReviewUsersSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/jobs/${id}/review-users`)
  if (ok) {
    yield put(getReviewUsersSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getViewersSaga(action) {
  const { customerId, id } = action
  const customer = customerId ? `?customerId=${customerId}` : ''
  const { ok, error, data } = yield http.get(`/jobs/${id}/viewers${customer}`)
  if (ok) {
    yield put(getViewersSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

function* initConfetti(candidates = 0) {
  // const state = yield select()
  // const placed =
  //   (
  //     (state.job.board.phases.find((x) => x.id === 'placed') || {})
  //       .candidates || []
  //   ).length + candidates
  // const number = state.entity.data.numberOfPlacements || 1
  // if (placed === number) {
  //   if (!state.entity.info.confetti) {
  yield put(showConfetti(true))
  // }
  yield delay(10000)
  yield put(showConfetti(false))
  // }
}

export function* loadSourcesSaga() {
  const { ok, data } = yield http.get('/jobs/sources')
  if (ok) {
    yield put(addSources(data))
  } else {
    yield call(globalError, data)
  }
}

export function* moveCandidateSaga(action) {
  const { type, ...other } = action
  const state = yield select()
  const id = state.entity.info.id
  const isNetive = state.entity.data.netive
  if (isNetive) {
    const proposal =
      state.auth.company.settings.proposalPhase === action.toPhase
    if (proposal) {
      // get info
      const { ok, data } = yield http.get(
        `/jobs/${id}/netive/proposal/${other.candidateId}`
      )
      if (ok) {
        if (!data.resumeId) {
          const yes = yield call(confirmSaga, {
            title: t('job.jobCandidates.netiveProposal.error.noResume.title'),
            content: t(
              'job.jobCandidates.netiveProposal.error.noResume.content'
            ),
          })
          if (yes) {
            yield put(
              gotoUrl({
                url: `/candidates/${other.candidateId}/resume/alternative`,
              })
            )
          } else {
            yield put(getPlacements(id))
          }
          return
        }
        yield put(netiveSetProposal(data))
        yield put(
          show('netiveProposal', { id, candidateId: other.candidateId })
        )
        const { cancel } = yield race({
          cancel: take(types.JOB_NETIVE_CONFIRM_CANCEL),
          yes: take(types.JOB_NETIVE_CONFIRM_YES),
        })
        if (cancel) {
          return yield put(getPlacements(id))
        }
      } else {
        yield call(globalError, data)
      }
    }
  }

  const placement = { ...other, index: state.job.board.placeholderIndex }
  const order = {
    [action.fromPhase]: state.job.board.phases
      .filter((phase) => phase.id === action.fromPhase)[0]
      .candidates.map((candidate) => candidate.id),
    [action.toPhase]: state.job.board.phases
      .filter((phase) => phase.id === action.toPhase)[0]
      .candidates.map((candidate) => candidate.id),
  }
  placement.order = order
  yield put(checkPhases())
  const { ok, error, data } = yield http.put(
    `/jobs/${id}/placements/`,
    placement
  )
  if (ok) {
    yield put(getPlacements(id))
    if (data.reload) {
      yield put(get(id))
    }
    if (data.askEmail) {
      yield put(
        askSendEmail({
          ...data.askEmail,
          phase: action.toPhase,
          jobName: state.entity.data.name,
        })
      )
    }
    if (action.toPhase === 'placed' && action.fromPhase !== action.toPhase) {
      yield call(initConfetti)
    }
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* moveCandidatesSaga(action) {
  const { toPhase, reason } = action
  const state = yield select()
  const id = state.entity.info.id
  const candidates = state.job.board.phases.reduce((total, phase) => {
    const list = [
      ...total,
      ...phase.candidates
        .filter((candidate) => candidate.selected)
        .map((candidate) => candidate.id),
    ]
    return list
  }, [])
  try {
    yield put(setLoading(true))
    const { ok, badRequest, error, data } = yield http.put(
      `/jobs/${id}/placements/`,
      {
        candidateId: candidates,
        toPhase,
        reason,
      }
    )
    if (ok) {
      yield put(getPlacements(id, candidates))
      if (action.toPhase === 'placed') {
        yield call(initConfetti, candidates.length)
      }
      if (data.reload) {
        yield put(get(id))
      }
      if (data.askEmail) {
        yield put(
          askSendEmail({
            ...data.askEmail,
            phase: action.toPhase,
            jobName: state.entity.data.name,
          })
        )
      }
    } else if (badRequest && data.error === 'Max matches reached') {
      if (data.number) {
        yield put(
          showMessage(
            t('job.message.placements.error.max', {
              number: data.number,
              name: data.name,
            }),
            messageLevel.warning
          )
        )
      } else {
        yield put(
          showMessage(
            t('job.message.placements.error.max0', { name: data.name }),
            messageLevel.warning
          )
        )
      }
      yield put(getPlacements(id, candidates))
    } else if (error) {
      yield call(globalError, data)
    }
  } finally {
    yield put(setLoading(false))
  }
}

export function* netiveUpdateProposalSaga(action) {
  const { id, candidateId, data } = action
  const { noContent, error } = yield http.put(
    `/jobs/${id}/netive/proposal/${candidateId}`,
    data
  )
  if (noContent) {
    // ok
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* removeCandidateSaga(action) {
  const { candidateId, name } = action
  const state = yield select()
  const id = state.entity.info.id
  const ok = yield call(confirmSaga, {
    title: t('job.message.removeCandidate.title'),
    content: t('job.message.removeCandidate.content', { name }),
  })
  if (!ok) {
    return
  }
  yield put(removeCandidateSucceed(candidateId))
  const { noContent, error, data } = yield http.delete(
    `/jobs/${id}/placements/${candidateId}`
  )
  if (noContent) {
    // ok
    yield put(refresh())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* removeImageSaga({ payload }) {
  const { id, number } = payload
  const ok = yield call(confirmSaga, {
    title: t('job.message.removeImage.title'),
    content: t('job.message.removeImage.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(
    `/jobs/${id}/images/${number}`
  )
  if (noContent) {
    yield put(get(id))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* selectCandidatesSaga(action) {
  const { id, candidates, reload } = action
  const { created, error, data } = yield http.post(`/jobs/${id}/placements/`, {
    candidates,
  })
  if (created) {
    yield put(gotoUrl({ url: `/jobs/${id}/candidates` }))
    yield delay(2000)
    yield put(checkPhases())
    if (reload) {
      yield put(getPlacements(id))
    }
    if (data.askEmail) {
      yield put(
        askSendEmail({
          ...data.askEmail,
          phase: 'selected',
        })
      )
    }
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* setPlacementOnholdSaga(action) {
  const {
    payload: { candidateId, jobId, onhold },
  } = action
  const { data, error } = yield http.put(`/jobs/${jobId}/placements/onhold`, {
    candidateId,
    onhold,
  })
  if (error) {
    yield call(globalError, data)
  }
}

export function* setPlacementOriginSaga(action) {
  const {
    payload: { candidateId, jobId, origin },
  } = action
  const { data, error } = yield http.put(`/jobs/${jobId}/placements/origin`, {
    candidateId,
    origin,
  })
  if (error) {
    yield call(globalError, data)
  }
}

export function* setPlacementStatusSaga(action) {
  const {
    payload: { candidates, jobId, status },
  } = action
  const { data, error } = yield http.put(`/jobs/${jobId}/placements/status`, {
    candidates,
    status,
  })
  if (error) {
    yield call(globalError, data)
  }
}

export function* timelineLoadSaga(action) {
  const { candidateId, jobId } = action
  yield put(timelineLoadSucceed([]))
  const { ok, error, data } = yield http.get(
    `/jobs/${jobId}/placements/${candidateId}/timeline`
  )
  if (ok) {
    yield put(timelineLoadSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateImageSaga({ payload }) {
  const { id, number, ...values } = payload
  const { ok, error, data } = yield http.put(
    `/jobs/${id}/images/${number}`,
    values
  )
  if (ok) {
    yield put(get(id))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateLanguageSaga(action) {
  const { id, language, values } = action
  yield put(editLanguage(false))
  const { ok, error, data } = yield http.put(
    `/jobs/${id}/languages/${language}`,
    values
  )
  if (ok) {
    yield put(get(id))
    yield put(getLanguageSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* uploadAlternativeSaga(action) {
  const { id, file } = action
  yield put(setUploading('alternative'))
  const { created, error, data, tooLarge } = yield http.post(
    `/jobs/${id}/alternative`,
    file
  )
  if (created) {
    //yield put(uploadAlternativeSucceed(id, data))
    return
  } else if (tooLarge) {
    yield put(
      showMessage(
        t('job.message.uploadVacancy.error.tooLarge', { file: file.name }),
        messageLevel.error
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setUploading('alternative', false))
}

export function* uploadDescriptionSaga(action) {
  const {
    payload: { values, resolve, reject },
  } = action
  const { id, ...file } = values
  const { created, error, data } = yield http.post(
    `/jobs/${id}/description`,
    file
  )
  if (created) {
    if (data.text) {
      yield put(
        updateField(
          { form: 'job', field: 'description', touch: true },
          data.text
        )
      )
      yield call(resolve)
    } else {
      yield put(showMessage(t('job.message.uploadDescription')))
      yield call(reject)
    }
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* uploadVacancySaga(action) {
  const { id, file } = action
  yield put(setUploading('vacancy'))
  const { created, error, data, tooLarge } = yield http.post(
    `/jobs/${id}/vacancy`,
    file
  )
  if (created) {
    // yield put(uploadVacancySucceed(id, data))
    return
  } else if (tooLarge) {
    yield put(
      showMessage(
        t('job.message.uploadVacancy.error.tooLarge', { file: file.name }),
        messageLevel.error
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setUploading('vacancy', false))
}

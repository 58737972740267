import React, { Component } from 'react'
import { connect } from 'react-redux'
import { show } from 'redux-modal'
import compose from 'recompose/compose'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Checkbox from '@material-ui/core/Checkbox'
import ClearIcon from '@material-ui/icons/Clear'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Tooltip from 'Components/common/Tooltip'
import { getDefaultIcon, getTitle } from 'Selectors/lookup'
import { toArray } from 'Utils/lookup'
import * as Icons from 'Icons'
import withTranslate from 'Root/app/withTranslate'
import SearchField from '../SearchField'

const styles = (theme) => ({
  checkbox: {
    height: 'auto',
    marginRight: 8,
    width: 'auto',
  },
  clear: {
    position: 'absolute',
    right: 0,
    top: 18,
  },
  clearEnabled: {
    cursor: 'pointer',
  },
  clearVisible: {
    paddingRight: '52px !important',
  },
  closeButton: {
    position: 'absolute',
    right: 1,
  },
  deletedItem: {
    textDecoration: 'line-through',
  },
  formControl: {
    width: '100%',
  },
  grow: {
    flex: '1 1 auto',
  },
  hideIcon: {
    display: 'none',
  },
  headline: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    display: 'flex',
    outline: 'none',
    paddingLeft: 6,
  },
  icon: {
    marginRight: 20,
  },
  iconLeft: {
    alignSelf: 'center',
    display: 'flex',
  },
  info: {
    maxWidth: 700,
  },
  item: {
    display: 'flex',
  },
  itemOneOnly: {
    flex: 1,
  },
  list: {
    display: 'flex',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  listIcon: {
    width: 20,
  },
  manageButton: {
    top: -4,
    right: 4,
  },
  menuItem: {
    height: 'auto',
  },
  notFound: {
    marginLeft: 10,
    marginTop: 5,
    outline: 'none',
  },
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  secondary: {
    paddingLeft: 5,
    whiteSpace: 'pre-line',
  },
  selectWithoutValue: {
    marginTop: -3,
  },
  selectWithValue: {
    height: 19,
    paddingTop: 3,
  },
  text: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  title: {
    cursor: 'default',
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 6,
  },
  valueNone: {
    color: theme.palette.primary.main,
    fontStyle: 'italic',
  },
  whiteIcon: {
    color: 'white',
  },
})

class SelectField extends Component {
  state = {
    filter: '',
    filterValue: '',
    open: false,
  }

  handleSearch = (value) => {
    this.setState(() => ({
      filter: (value || '').toUpperCase(),
      filterValue: value,
    }))
  }

  handleChange = (event) => {
    event.stopPropagation()
    if (event.target.value === '') {
      if (this.props.multiple) {
        event.target.value = []
      } else {
        event.target.value = null
      }
    }
    if (this.props.multiple && event.target.value) {
      event.target.value = (event.target.value || []).filter((x) => x !== null)
    }
    if (this.props.input.onChange) {
      this.props.input.onChange(event)
    }
    if (this.props.onChange) {
      this.props.onChange(event)
    }
  }

  handleHeaderClick = (event) => {
    event.stopPropagation()
    this.setState({ filter: '', filterValue: '', open: false })
  }
  handleClose = () => {
    this.setState({ filter: '', filterValue: '', open: false })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClearClick = (event) => {
    const { name } = this.props.input
    let target
    if (event.target) {
      target = event.target
    }
    event.persist()
    event.target = { ...target, value: '', name }
    this.handleChange(event)
  }

  showClear = () => {
    const { disabled, readOnly, multiple, emptyValue, required } = this.props
    const { value } = this.props.input
    if (disabled || readOnly) {
      return false
    }
    if (multiple && value && value.length === 0) {
      return false
    }
    if (!multiple && !emptyValue) {
      return false
    }
    if (!multiple && !value) {
      return false
    }
    if (required) {
      return false
    }
    return true
  }

  renderValue = (selected) => {
    const { classes, defaultIcon, items } = this.props
    const arrayItems = toArray(items)
    if (Array.isArray(selected)) {
      const values = selected
        .map((selection, index) => {
          const obj = arrayItems.find((item) => item.id === selection)
          if (obj) {
            const Icon =
              (obj.icon || defaultIcon) && Icons[obj.icon || defaultIcon]
            return (
              <div key={obj.id} className={classes.item}>
                {index > 0 && <div style={{ marginRight: 6 }}>,</div>}
                {Icon && (
                  <div className={classes.listIcon}>
                    <Icon
                      color={defaultIcon ? 'action' : 'inherit'}
                      style={{
                        fill: obj.color,
                        marginRight: 2,
                        height: 16,
                        width: 16,
                      }}
                    />
                  </div>
                )}
                <div
                  className={clsx(
                    obj.mutation && obj.mutation.deleted && classes.deletedItem
                  )}
                >
                  {obj.description}
                </div>
              </div>
            )
          }
          return <div key={selection} />
        })
        .filter((selection) => selection)
      return <div className={classes.list}>{values}</div>
    } else {
      const obj = arrayItems.find((item) => item.id === selected)
      if (obj) {
        const Icon = (obj.icon || defaultIcon) && Icons[obj.icon || defaultIcon]
        return (
          <div className={clsx(classes.item, classes.itemOneOnly)}>
            <div className={classes.iconLeft}>
              {Icon && (
                <Icon
                  color={defaultIcon ? 'action' : 'inherit'}
                  style={{
                    fill: obj.color,
                    marginRight: 6,
                    height: 16,
                    width: 16,
                  }}
                />
              )}
            </div>
            <div
              className={clsx(
                classes.text,
                obj.mutation && obj.mutation.deleted && classes.deletedItem
              )}
            >
              {obj.description}
            </div>
          </div>
        )
      }
      return ''
    }
  }

  render() {
    const {
      autoSearchBox = true,
      caption,
      child,
      classes,
      defaultIcon,
      disabled = false,
      disableUnderline = false,
      emptyValue,
      emptyValueCaption,
      excludes = [],
      input: { onBlur, ...inputProps },
      inputClassName,
      inputLabelProps,
      items = {},
      label = '',
      maxItems,
      meta: { touched, error, active, dirty },
      multiple = false,
      fullWidth = true,
      noSystem = false,
      onlySearchBox = false,
      onlySystem = false,
      noAdjustments = false,
      noSort = false,
      readOnly = false,
      required,
      showInfo = true,
      showLabel = true,
      showTitle = true,
      shrink,
      sortable = false,
      t,
      whiteIcon = false,
    } = this.props
    if (inputProps.value === '' && multiple) {
      inputProps.value = []
    }
    const { filter, filterValue, open } = this.state
    const listItems = toArray(items, noSort).filter((item) => {
      if (child && item.child) {
        const childArray = Array.isArray(child) ? child : [child]
        if (Array.isArray(item.child)) {
          if (!item.child.some((r) => childArray.includes(r))) {
            return false
          }
        } else if (!childArray.includes(item.child)) {
          return false
        }
      }
      if (child && !item.child) {
        return false
      }

      if (
        (onlySystem && !item.system) ||
        (noSystem && item.system) ||
        (excludes || []).includes(item.id)
      ) {
        return false
      }
      if (filter) {
        return item.description.toUpperCase().includes(filter)
      }
      return true
    })
    let values = inputProps.value
    if (!multiple) {
      if (inputProps.value) {
        if (!listItems.map((x) => x.id).includes(inputProps.value)) {
          inputProps.value = ''
          values = []
        } else {
          values = [inputProps.value]
        }
      } else {
        values = []
      }
    }
    const cleanItems = (listItems || []).filter(
      (item) =>
        !(item.mutation && item.mutation.deleted && !values.includes(item.id))
    )
    if (
      (cleanItems.length > 15 && autoSearchBox) ||
      sortable ||
      onlySearchBox
    ) {
      return (
        <SearchField
          lookupMode
          {...this.props}
          items={cleanItems}
          caption={caption}
          required={required}
          sortable={sortable}
        />
      )
    }
    return (
      <FormControl
        className={clsx(fullWidth && classes.formControl)}
        required={required}
        error={touched && !!error}
      >
        {showLabel && (
          <InputLabel {...{ shrink }} htmlFor={`${inputProps.name}_input`}>
            {label}
          </InputLabel>
        )}
        <Select
          classes={{
            select: clsx(
              !noAdjustments && inputProps.value && classes.selectWithValue,
              !noAdjustments && !inputProps.value && classes.selectWithoutValue,
              this.showClear() && classes.clearVisible
            ),
            icon: clsx(
              this.showClear() && classes.icon,
              (readOnly || disabled) && classes.hideIcon,
              whiteIcon && classes.whiteIcon
            ),
          }}
          multiple={multiple}
          open={open}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          MenuProps={{
            getContentAnchorEl: null,
            TransitionProps: {
              onExited: this.handleClose,
              timeout: 0,
            },
            MenuListProps: {
              subheader: showTitle && (
                <ListSubheader className={classes.headline}>
                  <Typography
                    className={classes.title}
                    variant="body2"
                    color="textSecondary"
                  >
                    <FormattedMessage
                      id="common.form.selectField.select.title"
                      values={{ list: label.toLowerCase() }}
                    />
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={this.handleClose}
                    className={classes.closeButton}
                  >
                    <ArrowDropUpIcon />
                  </IconButton>
                </ListSubheader>
              ),
            },
          }}
          disabled={disabled}
          {...inputProps}
          onChange={this.handleChange}
          input={
            <Input
              disableUnderline={disableUnderline}
              fullWidth={fullWidth}
              className={inputClassName}
              name={`${inputProps.name}_input`}
              spellCheck={false}
            />
          }
          renderValue={this.renderValue}
        >
          {emptyValue && !multiple && !filterValue && (
            <MenuItem className={classes.valueNone} value={''}>
              {emptyValueCaption || t('common.form.selectField.emptyValue')}
            </MenuItem>
          )}
          {filterValue && !emptyValue && listItems.length === 0 && (
            <MenuItem disabled value="">
              <Typography
                className={classes.notFound}
                variant="subtitle1"
                component="div"
                color="primary"
                noWrap
              >
                <FormattedMessage id="common.form.selectField.nothingFound" />
              </Typography>
            </MenuItem>
          )}
          {cleanItems.map((item) => {
            const Icon =
              (item.icon || defaultIcon) && Icons[item.icon || defaultIcon]
            let disableSelection = false
            if (multiple && maxItems !== 'undefined') {
              disableSelection =
                inputProps.value.length >= maxItems &&
                inputProps.value.indexOf(item.id) === -1
            }
            return (
              <MenuItem
                key={item.id}
                value={item.id}
                className={classes.menuItem}
                disabled={disableSelection}
              >
                {multiple && (
                  <Checkbox
                    color="default"
                    className={classes.checkbox}
                    checked={inputProps.value.indexOf(item.id) > -1}
                    disabled={disableSelection}
                  />
                )}
                {Icon && (
                  <Icon
                    color={defaultIcon ? 'action' : 'inherit'}
                    style={{ fill: item.color, marginRight: 10 }}
                  />
                )}
                <ListItemText
                  primary={item.description}
                  primaryTypographyProps={{
                    className: clsx(
                      item.mutation &&
                        item.mutation.deleted &&
                        classes.deletedItem
                    ),
                    noWrap: true,
                  }}
                  secondary={
                    item.info &&
                    showInfo && (
                      <div className={classes.info}>
                        {item.info.split('\\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    )
                  }
                  secondaryTypographyProps={{
                    className: clsx(
                      classes.secondary,
                      item.mutation &&
                        item.mutation.deleted &&
                        classes.deletedItem
                    ),
                    component: 'div',
                  }}
                />
              </MenuItem>
            )
          })}
        </Select>
        {this.showClear() && (
          <Tooltip title={t('common.form.selectField.button.clear.tooltip')}>
            <ClearIcon
              color="action"
              className={clsx(
                classes.clear,
                !(disabled || readOnly) && classes.clearEnabled
              )}
              onClick={disabled || readOnly ? null : this.handleClearClick}
            />
          </Tooltip>
        )}
        <FormHelperText error>{!active && dirty && error}</FormHelperText>
      </FormControl>
    )
  }
}

export default compose(
  withTranslate,
  withStyles(styles),
  withTheme,
  connect(
    (state, props) => ({
      defaultIcon: getDefaultIcon(state, props),
      title: getTitle(state, props),
    }),
    { show }
  )
)(SelectField)

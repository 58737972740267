import validUrl from 'valid-url'

const isValid = (url) => {
  if (validUrl.isUri(url)) {
    return url
  }
  return ''
}
export const getUrl = (type, uri) => {
  const url = uri || ''
  if (url.startsWith('http')) {
    return isValid(url)
  }
  if (url.startsWith('www')) {
    return isValid(`https://${url}`)
  }
  switch (type) {
    case 'email':
      return `mailto:${uri}`
    case 'phone':
      return `tel:${uri}`
    case 'address':
      return `https://www.google.com/maps?q=${uri}`
    //return `https://www.google.com/maps/@?api=1&map_action=map&q=${uri}`
    case 'linkedIn':
      if (url.toLowerCase().includes('linkedin.com')) {
        return isValid(`https://www.${url}`)
      } else {
        return isValid(`https://www.linkedin.com/in/${url}`)
      }
    case 'twitter':
      return isValid(`https://twitter.com/${url}`)
    case 'facebook':
      return isValid(`https://www.facebook.com/${url}`)
    case 'instagram':
      return isValid(`https://www.instagram.com/${url}`)
    case 'github':
      return isValid(`https://www.github.com/${url}`)
    case 'youTube': {
      let code
      const parts = (url || '').split('?')
      if (parts.length > 0 && parts[parts.length - 1].includes('v=')) {
        code = parts[parts.length - 1].replace('v=', '').split('&')[0]
      } else if (!(url || '').includes('www')) {
        code = isValid(url)
      }
      if (code) {
        return `https://www.youtube.com/watch?v=${code}`
      }
      return url
    }
    case 'vimeo': {
      return isValid(`https://player.vimeo.com/video/${url}`)
    }
    case 'blog':
    case 'video':
    case 'website':
      return isValid(`https://${url}`)
  }
  return ''
}

export const removeParameter = (param) => {
  const params = new URLSearchParams(window.location.search)
  params.delete(param)
  const newUrl = params.toString()
    ? `${window.location.pathname}?${params.toString()}`
    : window.location.pathname
  window.history.pushState({}, '', newUrl)
}

import { call, put, take } from 'redux-saga/effects'
import { http } from 'Utils/http'
import {
  addSources,
  deletePhotoSucceed,
  getCandidatesSucceed,
  getJobsSucceed,
  getShortlist,
  getShortlistSucceed,
  searchClaimsSucceed,
  searchCocSucceed,
  uploadPhotoSucceed,
} from 'Actions/relation'
import { addFilterItem, clearFilters } from 'Actions/table'
import { deselectItems } from 'Actions/entity'
import { confirmSaga, globalError } from './shell'
import { t } from 'Root/app/IntlProvider'
import * as types from 'Actions/types'
import { showMessage } from 'Actions/shell'
import { messageLevel } from 'Utils/constants'

export function* addShortlistSaga(action) {
  const { id, candidate } = action
  if (Array.isArray(candidate)) {
    const { ok, error, data } = yield http.post(`/relations/${id}/shortlist`, {
      candidates: candidate,
    })
    if (ok) {
      // ok
      yield put(getShortlist(id))
    } else if (error) {
      yield call(globalError, data)
    }
  } else {
    const { ok, error, data } = yield http.post(
      `/relations/${id}/shortlist/${candidate}`
    )
    if (ok) {
      yield put(getShortlist(id))
    } else if (error) {
      yield call(globalError, data)
    }
  }
}

export function* deletePhotoSaga(action) {
  const { id } = action
  const ok = yield call(confirmSaga, {
    title: t('relation.message.deletePhoto.ititle'),
    content: t('relation.message.deletePhoto.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/relations/${id}/photo`)
  if (noContent) {
    yield put(deletePhotoSucceed())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getCandidatesSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/relations/${id}/candidates`)
  if (ok) {
    yield put(getCandidatesSucceed(id, data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getJobsSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/relations/${id}/jobs`)
  if (ok) {
    yield put(getJobsSucceed(id, data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getPrimaryContactSaga(action) {
  const {
    payload: { customerId, resolve },
  } = action
  const { ok, error, data } = yield http.get(
    `/relations/${customerId}/primary-contact`
  )
  if (ok) {
    yield call(resolve, data)
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getShortlistSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/relations/${id}/shortlist`)
  if (ok) {
    yield put(getShortlistSucceed(id, data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* loadSourcesSaga() {
  const { ok, data } = yield http.get('/relations/sources')
  if (ok) {
    yield put(addSources(data))
  } else {
    yield call(globalError, data)
  }
}

export function* removeShortlistSaga(action) {
  const { id, candidateIds } = action
  const { ok, error, data } = yield http.post(
    `/relations/${id}/shortlist/remove`,
    { candidateIds }
  )
  if (ok) {
    yield put(getShortlist(id))
    yield put(deselectItems())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* searchClaimsSaga(action) {
  const { value } = action
  const { ok, error, data } = yield http.post(`/relations/search-claims`, {
    text: value,
  })
  if (ok) {
    yield put(searchClaimsSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* searchCocSaga(action) {
  const { value } = action
  const { ok, error, data } = yield http.post(`/relations/search-coc`, {
    name: value,
  })
  if (ok) {
    yield put(searchCocSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* selectContactsSaga(action) {
  const { relationType, selected } = action
  yield put(clearFilters(true))
  yield take(types.TABLE_CLEAR_FILTERS)
  console.log(relationType, selected)
  yield put(addFilterItem(relationType, selected))
  console.log('CLEAR')
}

export function* uploadPhotoSaga(action) {
  const { id, file } = action
  const ext = ((file || {}).name || '').toLowerCase().split('.').pop()
  const types = [
    'jpg',
    'jpeg',
    'png',
    'bmp',
    'webp',
    'heic',
    'heif',
    'svg',
    'jfif',
  ]
  if (!types.includes(ext)) {
    // message
    yield put(
      showMessage(
        t('common.form.upload.error.file', { types: types.join(', ') }),
        messageLevel.error
      )
    )
    return
  }
  const { ok, error, data } = yield http.post(`/relations/${id}/photo`, file)
  if (ok) {
    yield put(uploadPhotoSucceed(id, data.url))
  } else if (error) {
    yield call(globalError, data)
  }
}

import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const AccountKey = (props) => (
  <SvgIcon {...props} fill="none" className="h-8 w-8" viewBox="0 0 31 30">
    <path
      fill="url(#a)"
      d="m13.54 22.896 1.198-2.744c1.066-2.441 2.985-4.385 5.378-5.447l3.297-1.464c1.048-.465 1.048-1.99 0-2.455L20.22 9.368c-2.455-1.09-4.408-3.106-5.456-5.63L13.55.814a1.291 1.291 0 0 0-2.4 0L9.936 3.738c-1.047 2.524-3 4.54-5.456 5.63l-3.194 1.418c-1.048.465-1.048 1.99 0 2.455l3.297 1.464c2.394 1.062 4.313 3.006 5.379 5.447l1.197 2.744a1.291 1.291 0 0 0 2.38 0m11.993 6.68.337-.772a5.96 5.96 0 0 1 3.032-3.072l1.038-.462c.56-.249.56-1.065 0-1.314l-.98-.436a5.96 5.96 0 0 1-3.076-3.175l-.346-.834a.692.692 0 0 0-1.286 0l-.346.834a5.96 5.96 0 0 1-3.075 3.175l-.98.436c-.562.25-.562 1.065 0 1.314l1.038.462a5.96 5.96 0 0 1 3.031 3.072l.337.772a.692.692 0 0 0 1.276 0"
    />
    <defs>
      <linearGradient
        id="a"
        x1={-0.234}
        x2={45.56}
        y1={1.725}
        y2={23.695}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.009} stopColor="#411CA7" />
        <stop offset={1} stopColor="#E02692" />
      </linearGradient>
    </defs>
  </SvgIcon>
)

export default React.memo(AccountKey)

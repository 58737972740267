import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import compose from 'recompose/compose'
import numeral from 'numeral'
import moment from 'moment'
import { connect } from 'react-redux'
import AppRouter from '../routes/AppRouter'
import {
  loadLookups,
  loadCompanies,
  loadFilters,
  loadUsers,
} from 'Actions/cache'
import { loadCountries, loadLanguages } from 'Actions/lookup'
import { loadLists } from 'Actions/campaign'
import { hasRoleUser, isPluginAvailable, plugins } from 'Selectors/auth'

class IntlApp extends Component {
  componentDidMount() {
    this.setLanguage(this.props.language)
    if (this.props.isAuthenticated) {
      this.setLanguageAuth()
    }
  }

  componentDidUpdate(prevProps) {
    const { companyId, hasBm, language, loadFilters, isAuthenticated } =
      this.props
    if (language !== prevProps.language) {
      this.setLanguage(language)
    }
    if (
      (language !== prevProps.language ||
        isAuthenticated !== prevProps.isAuthenticated) &&
      isAuthenticated
    ) {
      this.setLanguageAuth()
    }
    if (
      (language !== prevProps.language ||
        companyId !== prevProps.companyId ||
        hasBm !== prevProps.hasBm) &&
      hasBm
    ) {
      this.setLanguageBm()
    }
  }

  setLanguage(language) {
    const { loadCountries, loadLanguages } = this.props
    numeral.locale(language)
    moment.locale(language)
    loadCountries()
    loadLanguages()
  }

  setLanguageAuth() {
    const { loadCompanies, loadFilters, loadLookups, loadUsers } = this.props
    loadLookups()
    loadCompanies()
    loadUsers()
    loadFilters(true)
  }

  setLanguageBm() {
    this.props.loadLists()
  }

  render() {
    return <AppRouter />
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      companyId: state.auth.company.id,
      isAuthenticated: state.auth.info.authenticated,
      hasBm:
        isPluginAvailable(state, plugins.bm) &&
        state.auth.company.settings.useBm &&
        hasRoleUser(state),
      language: state.app.language,
    }),
    {
      loadCompanies,
      loadCountries,
      loadFilters,
      loadLanguages,
      loadLists,
      loadLookups,
      loadUsers,
    }
  )
)(IntlApp)

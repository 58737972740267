import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'
import deepmerge from 'deepmerge'

const phaseHelper = {
  appendCandidatesToPhase: (phases, { phase, newCandidates, index }) => {
    newCandidates = newCandidates.map((candidate) => ({ ...candidate, phase }))
    const newPhases = [...phases]
    return [
      ...newPhases.map((item) => {
        if (item.id === phase) {
          let candidates = null
          if (index !== undefined) {
            const correctIndex = index
            if (index === -1) {
              candidates = [...newCandidates, ...item.candidates.slice(0)]
            } else {
              candidates = [
                ...item.candidates.slice(0, correctIndex),
                ...newCandidates,
                ...item.candidates.slice(correctIndex),
              ]
            }
          } else {
            candidates = [...item.candidates, ...newCandidates]
          }
          return { ...item, candidates }
        } else {
          return { ...item }
        }
      }),
    ]
  },

  appendCandidateToPhase: (state, { phase, candidate, index }) => {
    const newPhases = phaseHelper.appendCandidatesToPhase(state.phases, {
      phase,
      newCandidates: [candidate],
      index,
    })
    return { ...state, phases: newPhases }
  },
  assignTo: (state, { payload: { candidateId, assignedToUserId, phase } }) => {
    const newState = deepmerge({}, state)
    const lane = newState.phases.find((x) => x.id === phase)
    if (lane) {
      const candidate = lane.candidates.find((x) => x.id === candidateId)
      if (candidate) {
        candidate.assignedToUserId = assignedToUserId
      }
    }
    return newState
  },
  checkPhases: (state) => {
    const newPhases = [...state.phases].map((phase) => {
      const newPhase = { ...phase, candidates: [...phase.candidates] }
      newPhase.selected =
        newPhase.candidates.filter((x) => x.selected).length ===
          newPhase.candidates.filter((x) => !x.deleted).length &&
        newPhase.candidates.filter((x) => x.selected).length !== 0
      return newPhase
    })
    return {
      ...state,
      phases: newPhases,
    }
  },
  disableSelectMode: (state) => {
    const newState = deepmerge({}, state)
    for (const phase of newState.phases) {
      phase.selected = false
      for (const candidate of phase.candidates) {
        candidate.selected = false
      }
    }
    newState.selectMode = false
    return newState
  },
  removeCandidate: (state, { candidateId }) => {
    const newPhases = [
      ...state.phases.map((item) => {
        const candidates = item.candidates.filter(
          (candidate) => candidate.id !== candidateId
        )
        return { ...item, candidates }
      }),
    ]
    const newState = { ...state, phases: newPhases }
    return phaseHelper.reindexCandidates(newState)
  },

  reindexCandidates: (state) => {
    const phases = []
    for (const phase of state.phases) {
      const candidates = []
      for (const [index, candidate] of phase.candidates.entries()) {
        candidates.push({ ...candidate, index })
      }
      phases.push({ ...phase, candidates })
    }
    return { ...state, phases }
  },

  moveCandidate: (
    state,
    { fromPhase, toPhase, candidateId, index = state.placeholderIndex, reason }
  ) => {
    let candidateToMove = null
    const newPhases = [...state.phases]
    const interimPhases = newPhases.map((phase) => {
      if (phase.id === fromPhase) {
        candidateToMove = {
          ...phase.candidates.find((candidate) => candidate.id === candidateId),
        }
        candidateToMove.reason = reason
        candidateToMove.onhold = null
        if (fromPhase !== toPhase) {
          candidateToMove.days = 0
        }
        const candidates = phase.candidates.filter(
          (candidate) => candidate.id !== candidateId
        )
        return { ...phase, candidates }
      } else {
        return phase
      }
    })
    const interimState = { ...state, phases: interimPhases }
    const newState = phaseHelper.appendCandidateToPhase(interimState, {
      phase: toPhase,
      candidate: candidateToMove,
      index,
    })
    return phaseHelper.reindexCandidates(newState)
  },
  onhold: (state, { payload: { candidateId, onhold, phase } }) => {
    const newState = deepmerge({}, state)
    const lane = newState.phases.find((x) => x.id === phase)
    if (lane) {
      const candidate = lane.candidates.find((x) => x.id === candidateId)
      if (candidate) {
        if (onhold === 'off') {
          candidate.onhold = null
        } else {
          candidate.onhold = onhold
        }
      }
    }
    return newState
  },
  origin: (state, { payload: { candidateId, origin, phase } }) => {
    const newState = deepmerge({}, state)
    const lane = newState.phases.find((x) => x.id === phase)
    if (lane) {
      const candidate = lane.candidates.find((x) => x.id === candidateId)
      if (candidate) {
        candidate.origin = origin
      }
    }
    return newState
  },
  selectPhase: (state, payload) => {
    const newState = deepmerge({}, state)
    const { phase, candidates } = payload
    const ids = candidates.map((x) => x.id)
    const lane = newState.phases.find((x) => x.id === phase)
    if (lane) {
      if (lane.candidates.filter((x) => !x.deleted).length > 0) {
        lane.selected = !lane.selected
        lane.candidates = lane.candidates.map((candidate) => ({
          ...candidate,
          selected:
            !candidate.deleted &&
            candidate.canOpen &&
            ids.includes(candidate.id)
              ? lane.selected
              : !!candidate.selected,
        }))
      } else {
        lane.selected = false
      }
    }
    newState.selectMode = newState.phases.some(
      (x) => x.candidates.filter((candidate) => candidate.selected).length > 0
    )
    return newState
  },
  selectPhaseCandidate: (state, candidates) => {
    const newState = deepmerge({}, state)

    for (const lane of newState.phases) {
      for (const candidate of lane.candidates) {
        if ((candidates || []).includes(candidate.id)) {
          candidate.selected =
            !candidate.deleted && candidate.canOpen
              ? !candidate.selected
              : false
        }
      }
      lane.selected =
        lane.candidates.filter((x) => x.selected).length ===
          lane.candidates.filter((x) => !x.deleted).length &&
        lane.candidates.filter((x) => x.selected).length !== 0
    }
    newState.selectMode = newState.phases.some(
      (x) => x.candidates.filter((candidate) => candidate.selected).length > 0
    )
    return newState
  },
  status: (state, { payload: { candidates, status } }) => {
    const newState = deepmerge({}, state)
    for (const phase of newState.phases) {
      for (const id of candidates) {
        const candidate = phase.candidates.find((x) => x.id === id)
        if (candidate) {
          candidate.status = status
        }
      }
    }
    return newState
  },
}

const actionsReducer = createReducer(initialState.job.actions, {
  [types.JOB_SET_BARBUTTON]: (state, action) => {
    const { [action.button]: button, ...other } = state
    const { visible = true, disabled = true } = action
    return { ...other, [action.button]: { visible, disabled } }
  },
})

const boardReducer = createReducer(initialState.job.board, {
  [types.AUTH_CHANGE_COMPANY_SUCCEED]: (state) =>
    phaseHelper.disableSelectMode(state),
  [types.ENTITY_GET_SUCCEED]: (state, { entity, id }) => {
    if (
      entity === 'job' &&
      id !== state.filterId &&
      (state.filter || state.filterSelect || state.filterOrigin)
    ) {
      return {
        ...state,
        filter: '',
        filterId: '',
        filterOrigin: '',
        filterSelect: '',
      }
    } else {
      return state
    }
  },
  [types.JOB_ADD_CANDIDATE]: (state, action) =>
    phaseHelper.appendCandidateToPhase(state, action),
  [types.JOB_ASSIGN_PLACEMENT_TO]: (state, action) =>
    phaseHelper.assignTo(state, action),
  [types.JOB_CHECK_PHASES]: (state) => phaseHelper.checkPhases(state),
  [types.JOB_CLEAR_PLACEMENTS]: (state) => ({
    ...state,
    phases: state.phases.map((phase) => ({ ...phase, candidates: [] })),
  }),
  [types.JOB_DISABLE_SELECT_MODE]: (state) =>
    phaseHelper.disableSelectMode(state),
  [types.JOB_LOAD_CANDIDATES]: (state, { id, container }) => {
    const phases = [
      ...container.phases.map((phase) => {
        phase.candidates &&
          phase.candidates.forEach((candidate) => (candidate.phase = phase.id))
        return { ...phase }
      }),
    ]
    return { ...state, id, phases }
  },
  [types.JOB_MOVE_CANDIDATE]: (state, action) => {
    return phaseHelper.moveCandidate(state, action)
  },
  [types.JOB_REMOVE_CANDIDATE]: (state, action) =>
    phaseHelper.removeCandidate(state, action),
  [types.JOB_SELECT_CANDIDATES]: (state) => ({
    ...state,
    position: initialState.job.board.position,
  }),
  [types.JOB_SELECT_PHASE]: (state, { payload }) =>
    phaseHelper.selectPhase(state, payload),
  [types.JOB_SELECT_PHASE_CANDIDATE]: (state, { candidates }) =>
    phaseHelper.selectPhaseCandidate(state, candidates),
  [types.JOB_SET_BOARDFILTER]: (state, { filter, id }) => ({
    ...state,
    filter,
    filterId: id,
  }),
  [types.JOB_SET_BOARDFILTER_ORIGIN]: (state, { filter, id }) => ({
    ...state,
    filterOrigin: filter,
    filterId: id,
  }),
  [types.JOB_SET_BOARDFILTER_SELECT]: (state, { filter, id }) => ({
    ...state,
    filterSelect: filter,
    filterId: id,
  }),
  [types.JOB_SET_PLACEHOLDERINDEX]: (state, action) => ({
    ...state,
    placeholderIndex: action.placeholderIndex,
  }),
  [types.JOB_SET_PLACEMENT_ONHOLD]: (state, action) =>
    phaseHelper.onhold(state, action),
  [types.JOB_SET_PLACEMENT_ORIGIN]: (state, action) =>
    phaseHelper.origin(state, action),
  [types.JOB_SET_PLACEMENT_STATUS]: (state, action) =>
    phaseHelper.status(state, action),
  [types.JOB_SET_SCROLL_POSITION]: (state, { top, left, id }) => ({
    ...state,
    position: {
      id,
      left,
      top,
    },
  }),
})

const languagesReducer = createReducer(initialState.job.languages, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.job.languages,
  [types.ENTITY_RESET_DATA]: () => initialState.job.languages,
  [types.JOB_EDIT_LANGUAGE]: (state, { value }) => ({
    ...state,
    editmode: value,
  }),
  [types.JOB_GET_LANGUAGE_SUCCEED]: (state, { data }) => ({
    ...state,
    data,
  }),
})

const netiveReducer = createReducer(initialState.job.netive, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.job.netive,
  [types.ENTITY_RESET_DATA]: () => initialState.job.netive,
  [types.JOB_NETIVE_SET]: (state, { data }) => ({
    ...state,
    data,
  }),
  [types.JOB_NETIVE_SET_JSON]: (state, { json }) => ({
    ...state,
    json,
  }),
  [types.JOB_NETIVE_SET_PROPOSAL]: (state, { proposal }) => ({
    ...state,
    proposal,
  }),
})

const reviewUsersReducer = createReducer(initialState.job.reviewUsers, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.job.reviewUsers,
  [types.JOB_GET_REVIEW_USERS_SUCCEED]: (state, { data }) => ({
    ...state,
    list: data,
  }),
})

const selectBarReducer = createReducer(initialState.job.selectBar, {
  [types.JOB_SELECTBAR_SET_CANDIDATEID]: (state, action) => ({
    ...state,
    candidateId: action.value,
  }),
  [types.JOB_SELECTBAR_SET_MATCHING]: (state, action) => ({
    ...state,
    matching: action.value,
  }),
})

const sourcesReducer = createReducer(initialState.job.sources, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.job.sources,
  [types.AUTH_CHANGE_COMPANY_SUCCEED]: () => initialState.job.sources,
  [types.JOB_ADD_SOURCES]: (state, { data }) => ({
    ...state,
    data,
  }),
  [types.JOB_SET_SOURCES_DIRTY]: (state, { value }) => ({
    ...state,
    dirty: value,
  }),
})

const textReducer = createReducer(initialState.job.text, {
  [types.JOB_PDF_ZOOMIN]: (state) => ({
    ...state,
    scale: state.scale + 0.5,
  }),
  [types.JOB_PDF_ZOOMOUT]: (state) => ({
    ...state,
    scale: state.scale - 0.5,
  }),
  // [types.JOB_SET_UPLOADING]: (state, action) => ({
  //   ...state,
  //   uploading: action.value,
  // }),
})

const timelineReducer = createReducer(initialState.job.timeline, {
  [types.JOB_TIMELINE_LOAD_SUCCEED]: (state, { values }) => ({
    ...state,
    list: values,
  }),
})

const viewersReducer = createReducer(initialState.job.viewers, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.job.viewers,
  [types.JOB_GET_VIEWERS_SUCCEED]: (state, { data }) => ({
    ...state,
    list: data,
  }),
})

export default combineReducers({
  actions: actionsReducer,
  board: boardReducer,
  languages: languagesReducer,
  netive: netiveReducer,
  reviewUsers: reviewUsersReducer,
  selectBar: selectBarReducer,
  sources: sourcesReducer,
  text: textReducer,
  timeline: timelineReducer,
  viewers: viewersReducer,
})

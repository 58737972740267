import React from 'react'
import history from 'Utils/history'
import moment from 'moment'
import { show } from 'redux-modal'
import { http } from 'Utils/http'
import { all, call, delay, put, select } from 'redux-saga/effects'
import { showMessage } from 'Actions/shell'
import { confirmSaga, globalError } from './shell'
import { t } from 'Root/app/IntlProvider'
import {
  addAttachmentSucceed,
  decrementUploading,
  incrementUploading,
  removeTemplateAttachments,
} from 'Actions/email'
import { uploadResume } from 'Actions/candidate'
import { get } from 'Actions/entity'
import { messageLevel } from 'Utils/constants'
import { getNumber } from 'Actions/deletion'

export function* addResumeSaga(action) {
  const { attachmentId, candidateId } = action
  const yes = yield call(confirmSaga, {
    title: t('email.message.add.resume.title'),
    content: t('email.message.add.resume.content'),
    options: { delay: 2000 },
  })
  if (!yes) {
    return
  }
  const { ok, data, notFound } = yield http.get(
    `/emails/attachments/${attachmentId}`
  )
  if (ok) {
    const file = data.file
    const values = {
      content: file.data,
      name: file.name,
      size: file.size,
      type: file.type,
    }
    yield put(uploadResume(candidateId, values, false))
  } else if (notFound) {
    yield put(
      showMessage(t('email.message.attachment.notFound'), messageLevel.error)
    )
  } else {
    yield call(globalError, data)
  }
}

export function* addRecipients(action) {
  const {
    context,
    emailId,
    entities,
    forwardId,
    recipients,
    reject,
    replyId,
    resolve,
  } = action.payload

  const { ok, data, error } = yield http.post(`/emails/recipients`, {
    context,
    emailId,
    entities,
    forwardId,
    replyId,
  })
  const result = { ...recipients }
  let order = Object.values(result).reduce(
    (tot, item) => (item.order > tot ? item.order : tot),
    0
  )

  if (ok) {
    for (const recipient of data.recipients || []) {
      const find = Object.values(result).find(
        (x) =>
          x.entity === recipient.entity &&
          x.options.email === recipient.options.email
      )
      if (!find) {
        const id = `${order}|${recipient.id}`
        order++
        result[id] = {
          color: recipient.color,
          entity: recipient.entity,
          icon: recipient.icon,
          id,
          language: recipient.language,
          mutation: {
            deleted: false,
          },
          name: recipient.name,
          options: recipient.options,
          order,
          system: false,
        }
      }
    }
    yield call(resolve, result)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* connectSaga({ id, payload }) {
  const { email, name, ...rest } = payload
  const yes = yield call(confirmSaga, {
    title: t('email.message.connect.title'),
    content: t('email.message.connect.content', { email, name }),
    options: { delay: 1000 },
  })
  if (!yes) {
    return
  }
  const { ok, data } = yield http.put(`/emails/${id}/connect`, rest)
  if (ok) {
    yield put(get(id))
  } else {
    yield call(globalError, data)
  }
}

export function* createAttachmentSaga(action) {
  const { file } = action
  yield put(incrementUploading())
  const { created, tooLarge, error, data } = yield http.post(
    `/emails/attachments`,
    file
  )
  yield put(decrementUploading())
  if (created) {
    yield put(addAttachmentSucceed([data]))
  } else if (tooLarge) {
    yield put(
      showMessage(
        t('email.newEmail.error.attachment.tooLarge'),
        messageLevel.error
      )
    )
  } else if (data.error === 'Extension is forbidden') {
    yield put(
      showMessage(
        t('email.newEmail.error.attachment.extension', {
          extension: data.extension,
          file: data.file,
        }),
        messageLevel.error
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* createCandidateSaga(action) {
  const { attachmentId } = action
  const { ok, data, notFound } = yield http.get(
    `/emails/attachments/${attachmentId}`
  )
  if (ok) {
    yield put(show('addCandidate', data))
  } else if (notFound) {
    yield put(
      showMessage(t('email.message.attachment.notFound'), messageLevel.error)
    )
  } else {
    yield call(globalError, data)
  }
}

export function* deleteDraftSaga(action) {
  const {
    payload: { id, resolve, reject },
  } = action
  const ok = yield call(confirmSaga, {
    title: t('email.message.draft.delete.title'),
    content: t('email.message.draft.delete.content'),
    options: { delay: 1000 },
  })
  if (!ok) {
    return yield call(reject)
  }
  const { data, noContent, forbidden, notFound } = yield http.delete(
    `/emails/${id}`
  )
  if (forbidden) {
    yield put(
      showMessage(
        t('entity.delete.error.forbidden', { name: data.name }),
        messageLevel.error
      )
    )
    return yield call(reject)
  } else if (noContent || notFound) {
    const state = yield select()
    if (state.entity.info.id === id) {
      yield put(get(id))
    }
    yield call(resolve)
    yield delay(3000)
    yield put(getNumber())
  }
}

export function* getTemplateSaga(action) {
  const {
    payload: {
      attachments = [],
      bulk = false,
      entities,
      forwardId,
      freetext,
      from,
      id,
      includeAttachments = false,
      language,
      mode,
      onlyJson,
      reject,
      replyId,
      resolve,
      subject,
      to = {},
    },
  } = action
  const { ok, error, data } = yield http.post(`/emails/templates/${id}`, {
    attachments,
    bulk,
    entities,
    forwardId,
    freetext,
    from,
    includeAttachments,
    language,
    mode,
    onlyJson,
    replyId,
    subject,
    to,
  })
  if (ok) {
    yield call(resolve, data)
    yield put(removeTemplateAttachments())
    if ((data.attachments || []).length > 0) {
      yield put(addAttachmentSucceed(data.attachments))
    }
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* saveJobSaga(action) {
  const { id, jobId } = action
  const { error, data } = yield http.put(`/emails/${id}`, {
    jobId,
  })
  if (error) {
    yield call(globalError, data)
  }
}

export function* sendSaga(action) {
  const { values } = action
  const {
    allRecipients,
    attachmentsRef,
    attachments,
    bcc,
    callback,
    candidateId,
    cc,
    content,
    context,
    draft,
    emailId,
    entities,
    forwardId,
    from,
    jobId,
    label,
    language,
    replyId,
    scheduledAt,
    style,
    subject,
    template,
    to,
    toEntity = '',
  } = values
  const recipients = []

  const addRecipient = (id, field) => {
    const recipient = allRecipients[id]
    recipients.push({
      id: (id || '').split('|').pop(),
      email: recipient.options.email,
      entity: recipient.entity,
      field,
      name: recipient.options.name,
    })
  }
  for (const item of to) {
    addRecipient(item, 'to')
  }
  for (const item of cc) {
    addRecipient(item, 'cc')
  }
  for (const item of bcc) {
    addRecipient(item, 'bcc')
  }
  const { ok, error, data, notFound, tooManyRequests, unprocessableEntity } =
    yield http.post(`/emails`, {
      attachments,
      attachmentsRef,
      candidateId,
      content,
      context,
      draft,
      emailId,
      entities,
      forwardId,
      from,
      jobId,
      label,
      language,
      recipients,
      replyId,
      scheduledAt,
      style,
      subject,
      templateId: template,
      toEntity,
    })
  const handleClick = (id) => () => {
    history.push(`/emails/${id}`)
  }
  if (ok) {
    if (emailId) {
      // reload entity
      const state = yield select()
      if (state.entity.info.id === data.id) {
        yield put(get(data.id))
      }
    }
    yield put(
      showMessage(
        <a
          onClick={handleClick(data.id)}
          style={{ cursor: 'pointer', color: 'white' }}
        >
          {scheduledAt
            ? t('email.message.scheduled.single', {
                date: moment(scheduledAt).format('D-M-YYYY HH:mm'),
              })
            : t(`email.message.${draft ? 'saved' : 'send'}`)}
        </a>,
        messageLevel.success,
        3000
      )
    )

    if (callback) {
      callback()
    }
  } else if (tooManyRequests) {
    yield put(
      showMessage(t('global.error.tooManyRequests'), messageLevel.error)
    )
  } else if (notFound) {
    let msg = 'email.message.error'
    switch (data.error) {
      case 'Email is deleted':
        msg = 'email.message.deleted'
        break
      case 'Job not found':
        msg = 'email.message.job.notfound'
        break
      case 'Recipient not found':
        msg = 'email.message.recipient.notfound'
        break
      case 'Email is sent':
        msg = 'email.message.issent'
        break
    }
    yield put(showMessage(t(msg), messageLevel.error, 5000))
  } else if (unprocessableEntity) {
    yield put(
      showMessage(
        <a
          onClick={handleClick(data.id)}
          style={{ cursor: 'pointer', color: 'white' }}
        >
          {t('email.message.error', { error: data.error })}
        </a>,
        messageLevel.error,
        5000
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* sendBulkSaga(action) {
  const { values } = action
  const { ok, error, data, unprocessableEntity } = yield http.post(
    `/emails/bulk`,
    values
  )
  if (ok) {
    const { scheduledAt } = values
    if (scheduledAt) {
      yield put(
        showMessage(
          t('email.message.scheduled.bulk', {
            date: moment(scheduledAt).format('D-M-YYYY HH:mm'),
          }),
          messageLevel.success,
          3000
        )
      )
    } else {
      yield put(
        showMessage(t('email.message.sendBulk'), messageLevel.success, 3000)
      )
    }
  } else if (unprocessableEntity) {
    yield put(
      showMessage(
        t('email.message.error', { error: data.error }),
        messageLevel.error,
        5000
      )
    )
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* showBulkEmailSaga(action) {
  const { options } = action
  const senders = yield http.get('/emails/senders')
  const props = {
    ...options,
    senders: senders.data,
    from: senders.data ? senders.data[0].id : '',
  }
  yield put(show('bulkEmail', props))
}

export function* showNewEmailSaga(action) {
  const { options } = action
  const [recipients, senders] = yield all([
    http.post(`/emails/recipients`, {
      context: options.context,
      emailId: options.emailId,
      entities: options.entities,
      forwardId: options.forwardId,
      replyId: options.replyId,
    }),
    http.get('/emails/senders'),
  ])
  if (recipients.ok && senders.ok) {
    const state = yield select()
    const userId = state.auth.user.id
    const addBcc = state.auth.user.settings.bccMe
    let bcc =
      addBcc && options.to !== userId && !(options.cc || []).includes(userId)
        ? state.auth.user.id
        : null
    let { attachments, ...rest } = recipients.data
    const props = {
      ...options,
      ...rest,
      senders: senders.data,
      bcc,
    }
    attachments = attachments || options.attachments
    if (attachments && attachments.length > 0) {
      yield put(addAttachmentSucceed(attachments))
    }
    yield put(show('newEmail', props))
  } else if (recipients.error || senders.error) {
    yield call(globalError, recipients.data || senders.data)
  }
}

export function* translateSaga(action) {
  const { values, resolve, reject } = action.payload
  const { ok, error, data } = yield http.post(`/emails/translate`, values)
  if (ok) {
    yield call(resolve, data)
  } else if (error) {
    yield call(globalError, data, reject)
  }
}

export function* viewGetSaga(action) {
  const { id } = action
  if (!id) {
    return
  }
  const { ok, error, data } = yield http.get(`/emails/view/${id}`)
  if (ok) {
    if (data) {
      yield put(show('emailView', { data }))
    }
  } else if (error) {
    yield call(globalError, data)
  }
}

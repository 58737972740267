import * as types from './types'
import { createAction } from 'Utils/redux'

export const addMyTasks = createAction(types.TASK_ADD_MY_TASKS, 'data')
export const addTasks = createAction(types.TASK_ADD_TASKS, 'data')
export const completed = createAction(
  types.TASK_COMPLETED,
  'id',
  'completed',
  'callback'
)
export const create = createAction(types.TASK_CREATE, 'values')
export const getItem = createAction(
  types.TASK_GET_ITEM,
  'id',
  'options',
  'callback'
)
export const getItemSucceed = createAction(types.TASK_GET_ITEM_SUCCEED, 'data')
export const getSharedTasks = createAction(types.TASK_GET_SHARED_TASKS)
export const getSharedTasksSucceed = createAction(
  types.TASK_GET_SHARED_TASKS_SUCCEED,
  'users'
)
export const loadMyTasks = createAction(types.TASK_LOAD_MY_TASKS)
export const loadTasks = createAction(types.TASK_LOAD_TASKS)
export const setFilter = createAction(types.TASK_SET_FILTER, 'filter')
export const setFilterLocation = createAction(
  types.TASK_SET_FILTER_LOCATION,
  'filter'
)
export const setFormOpen = createAction(types.TASK_SET_FORM_OPEN, 'value')
export const setLoading = createAction(types.TASK_SET_LOADING, 'value')
export const setSearchFilter = createAction(
  types.TASK_SET_SEARCH_FILTER,
  'filter'
)
export const setSharedUsers = createAction(types.TASK_SET_SHARED_USERS, 'users')
export const setTab = createAction(types.TASK_SET_TAB, 'tab')
export const setTabValue = createAction(types.TASK_SET_TAB_VALUE, 'tab')
export const sharedTasksChanged = createAction(
  types.TASK_SHARED_TASKS_CHANGED,
  'users'
)
export const update = createAction(types.TASK_UPDATE, 'id', 'values')
